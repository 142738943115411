import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { QuickCustomerEntryComponent } from './quick-customer-entry.component';
import { MatMenuModule } from '@angular/material/menu';

import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
 
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule  } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';

import { SecondaryToolbarModule } from './../../../../@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from './../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import { DateTokensModule } from './../../../../@vex/pipes/date-tokens/date-tokens.module';
import { PopoverModule } from './../../../../@vex/components/popover/popover.module';

import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { ScrollbarModule } from '../.../../../../../@vex/components/scrollbar/scrollbar.module';
import { MatRippleModule } from '@angular/material/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';


 
@NgModule({
  imports: [
    MatSidenavModule,
    MatSnackBarModule,
    MatRippleModule,
    ScrollbarModule,
    PageLayoutModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    DateTokensModule,
    PopoverModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    FormsModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatTabsModule,
    MatPaginatorModule, 
    MatTableModule, 
    MatSortModule,  
    MatTooltipModule,
    MatCardModule, 
    DragDropModule,  
    MatExpansionModule, 
  ],
  declarations: [QuickCustomerEntryComponent],
  exports: [QuickCustomerEntryComponent]
})
export class QuickCustomerEntryModule {}
