import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { MatDialog } from '@angular/material/dialog';



import { ApplicationService } from 'src/app/common-service/application.service';
import { AccountService } from 'src/app/common-service/account.service';
import { checkLoginoutput, checkLoginparam, checkLoginresult, StockCatcheckLoginoutput } from 'src/app/pages/pages/auth/login/login.component';
import { OrganizationUnit } from './pages/pages/application-settings/organization/interfaces/organization.module';
import { environment } from 'src/environments/environment';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './pages/pages/auth/login/login.service';
import { DialogResultComponent } from './pages/pages/commonPage/dialog-result/dialog-result.component';
import { MenuRights } from './common-interface/menu-rights.module';


@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  logout() {
    this.accountService.logout();
  }
  title = ' STOCKCAT';
  user: checkLoginoutput;
  private userSubject: BehaviorSubject<checkLoginoutput>;
  private userobj: checkLoginoutput;
  stockoutput: Array<checkLoginoutput>;
  StockCatuser: StockCatcheckLoginoutput;
  form: checkLoginparam;

  checkresult: checkLoginresult[] = [];
  checkoutput: Array<checkLoginoutput>;


  private menuSubject: BehaviorSubject<MenuRights[]>;
  menudata: MenuRights[] = [];;


  items: NavigationItem[] = [];
  orgunit: OrganizationUnit;

  constructor(private dialog: MatDialog,private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer,
              private router: Router,
              public Application: ApplicationService,
              private accountService: AccountService,
              public Login: LoginService,
              )
               {
    Settings.defaultLocale = this.localeId;
  
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }

      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */


    
  this.navigationService.items = [
    {
      type: 'subheading',
      label: 'Dashboards',
      visiable: true,
      activityID: '',     
      children: [
        {
          type: 'link',
          label: 'Analytics',
          route: '/',
          icon: 'mat:insights',
          visiable: true,
          activityID: '',     
          routerLinkActiveOptions: { exact: true }
        },
        /*
        {
          type: 'link',
          label: 'Finance',
          route: '/',
          icon: icLayers,
          routerLinkActiveOptions: { exact: true }
        },
         */
      ]
    },

    
    {
      type: 'subheading',
      label: 'Lead',
      visiable: false,
      activityID: 'TRANLEAD',  
      children: [
      {
        type: 'dropdown',
        label: 'Lead',
        icon: 'mat:note_add',    
        visiable: false,
        activityID: '',     
        children: [
          {
            type: 'link',
            label: 'Entry',
            route: '/pages/lead-generation',
            visiable: false,
            activityID: '',   
          },
          {
            type: 'link',
            label: 'View',
            route: '/pages/lead-generation/view',
            visiable: false,
            activityID: '',   
          }
        ]
      },   
        ]
    },
 

    {
      type: 'subheading',
      label: 'Product',
      visiable: false,
      activityID: 'MASTERPRODUCTVIEW',  
      children: [
      {
        type: 'dropdown',
        label: 'Product',
        icon: 'mat:note_add',    
        visiable: false,
        activityID: 'MASTERPRODUCTVIEW',     
        children: [
          {
            type: 'link',
            label: 'Entry',
            route: '/pages/product-entry',
            visiable: false,
            activityID: 'MASTERPRODUCTADD',   
          },
          {
            type: 'link',
            label: 'View',
            route: '/pages/product-view',
            visiable: false,
            activityID: 'MASTERPRODUCTVIEW',   
          },        
          {
            type: 'link',
            label: 'Import & Export Data',
            route: '/pages/import-export',
            visiable: false,
            activityID: 'MASTERPRODUCTUPLOADENTRY',   
          },     

          {
            type: 'link',
            label: 'Rate-change',
            route: '/pages/product-rate',
            visiable: true,
            activityID: '',   
          },   

        ]
      },   
        ]
    },

    {
      type: 'subheading',
      label: 'Purchase',
      visiable: false,
      activityID: 'TRANPURCHASE',  
      children: [
          {
            type: 'link',
            label: 'Payment-Out',
            route: '/pages/payment-out',
            icon: 'mat:receipt',
            visiable: false,
            activityID: 'TRANPAYAGCREDUTPUR',
          },
          {
            type: 'dropdown',
            label: 'Purchase Bill',
            icon: 'mat:settings',    
            visiable: false,
            activityID: 'TRANPURCHASE',     
            children: [
              {
                type: 'link',
                label: 'Entry',
                route: '/pages/purchase-bill',
                icon: 'mat:person',
                visiable: false,
                activityID: 'TRANPURCHASE'
              },
              {
                type: 'link',
                label: 'View',
                route: '/pages/purchase-bill-view',
                icon: 'mat:person',
                visiable: false,
                activityID: 'TRANPURCHASEVIEW'
              },            
            ]
          },   
      ]
    },
    {
      type: 'subheading',
      label: 'Accounts',
      visiable: false,
      activityID: 'TRANACCOUNT',
      children: [
        {
          type: 'link',
          label: 'Expense',
          route: '/pages/expense',
          icon: 'mat:account_balance_wallet',
          visiable: false,
          activityID: ''
        },
      ]
    },

    {
      type: 'subheading',
      label: 'Stock',
      visiable: true,
      activityID: '',  
      children: [
        {
          type: 'link',
          label: 'Direct-Stock',
          route: '/pages/direct-stock',
          icon: 'mat:label',
          visiable: false,
          activityID: 'TRAN_DSTOCK_C1'
        },
        {
          type: 'link',
          label: 'Stock-Adjustment',
          route: '/pages/stock-adjustment',
          icon: 'mat:label',
          visiable: false,
          activityID: 'STOCK_ADJ'
        },
      ]
    },

    {
      type: 'subheading',
      label: 'Sales',
      visiable: false,
      activityID: 'TRAN',  
      children: [
        {
          type: 'link',
          label: 'Payment-In',
          route: '/pages/payment-in',
          icon: 'mat:receipt',
          visiable: false,
          activityID: 'TRANRECAGCREDITSALE',
        },
        {
          type: 'dropdown',
          label: 'Sale-Invoice',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'TRANSALESINVOICENEW', 
          children: [
            {
              type: 'link',
              label: 'Entry',
              visiable: false,
              activityID: 'TRANSALESINVOICENEW',
              route: '/pages/sale-invoice',
            },
            {
              type: 'link',
              label: 'View',
              visiable: false,
              activityID: 'TRANSALESINVOICEVIEW',
              route: '/pages/sale-invoice-view',
            },            
          ]
        },  
        {
          type: 'dropdown',
          label: 'Sale-Invoice',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'TRANSALESRESTINVOICENEW', 
          children: [
            {
              type: 'link',
              label: 'Entry',
              visiable: false,
              activityID: 'TRANSALESRESTINVOICENEW',
              route: '/pages/sale-invoice-f1',
            },
            {
              type: 'link',
              label: 'View',
              visiable: false,
              activityID: 'TRANSALESRESTINVOICEVIEW',
              route: '/pages/sale-invoice-view-f1',
            },            
          ]
        },  

        {
          type: 'dropdown',
          label: 'Booking Order',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'TRAN_ORDER_C1', 
          children: [
            {
              type: 'link',
              label: 'Entry',
              visiable: false,
              activityID: 'TRAN_ORDER_C1',
              route: '/pages/order-single',
            },
            {
              type: 'link',
              label: 'View',
              visiable: false,
              activityID: 'TRAN_ORDER_C1_VIEW',
              route: '/pages/order-single-view',
            },            
          ]
        }, 

        {
          type: 'link',
          label: 'Receipt',
          route: '/pages/customer-advance',
          icon: 'mat:receipt',
          visiable: false,
          activityID: 'TRANRECADVFROMCUST',
        },
        {
          type: 'link',
          label: 'Voucher Entry',
          icon: 'mat:receipt',
          visiable: false,
          activityID: 'TARNVOUCHER_C3',
          route: '/pages/manual-payment'
        },
        {
          type: 'link',
          label: 'RTN Payment',
          icon: 'mat:receipt',
          visiable: false,
          activityID: 'TARNRTNVOUCHER_C3',
          route: '/pages/return-entry'
        },
        {
          type: 'link',
          label: 'Journal Entry',
          icon: 'mat:receipt',
          visiable: false,
          activityID: 'TARNRJOURNAL',
          route: '/pages/journal-entry'
        },

        {
          type: 'dropdown',
          label: 'Delivery-Challan',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'TRAN_DC_C1', 
          children: [
            {
              type: 'link',
              label: 'Entry',
              visiable: false,
              activityID: 'TRAN_DC_C1',
              route: '/pages/dc-entry-single',
            },
            {
              type: 'link',
              label: 'View',
              visiable: false,
              activityID: 'TRAN_DC_C1_VIEW',
              route: '/pages/dc-entry-single-view',
            },            
          ]
        },  

 
        {
          type: 'dropdown',
          label: 'Delivery-Challan',
          icon: 'mat:receipt',        
          visiable: false,
          activityID: 'TRAN_DC',
          children: [
            {
              type: 'link',
              label: 'Entry',
              visiable: false,
              activityID: 'TRAN_DC',
              route: '/pages/dc-entry-multi',
            },
            {
              type: 'link',
              label: 'View',
              visiable: false,
              activityID: 'TRAN_DCVIEW',
              route: '/pages/dc-entry-multi-view',
            },            
          ]
        },   
        {
          type: 'link',
          label: 'SalesOrder / Quotation',
          route: '/pages/sales-order-multi',
          icon: 'mat:receipt',
          visiable: false,
          activityID: 'TRANSALEORD',
        },
        {
          type: 'link',
          label: 'Appointment',
          route: '/pages/appointment-view',
          icon: 'mat:receipt',
          visiable: false,
          activityID: 'TRANSALEORD',
        },
      ]
    },

    {
      type: 'subheading',
      label: 'Reports',
      visiable: true,
      activityID: '',
      children: [
        {
          type: 'link',
          label: 'Current Stock Report',
          route: '/reports/tag-stock-report',
          icon: 'mat:label',
          visiable: false,
          activityID: 'RPT_TAGCURRENTSTOCK_C1_VIEW'
        },

        {
          type: 'dropdown',
          label: 'Stock',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'REPORTCURTSTOCK', 
          children: [
            {
              type: 'link',
              label: 'Current Stock Report',
              visiable: false,
              activityID: 'REPORTCURTSTOCK',
              route: '/reports/current-stock-report',
            },         
          ]
        },  


        {
          type: 'dropdown',
          label: 'Purchase',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'REPORTPRODWISEREP', 
          children: [
            {
              type: 'link',
              label: 'Product Wise Purchase',
              visiable: false,
              activityID: 'REPORTPRODWISEREP',
              route: '/reports/Product-Wise-Purchase-Report',
            },         
          ]
        },  

        {
          type: 'dropdown',
          label: 'Sales',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'REPORTPRODWISESALEREP', 
          children: [
            {
              type: 'link',
              label: 'Product Wise Sales',
              visiable: false,
              activityID: 'REPORTPRODWISESALEREP',
              route: '/reports/Product-Wise-sales-Report',
            },      
            {
              type: 'link',
              label: 'Credit-Sales Summary',
              visiable: false,
              activityID: 'REPORTCREDITSUMMARY',
              route: '/reports/credit-sales-summary',
            },    
          ]
        },  
        {
          type: 'dropdown',
          label: 'GST REPORT',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'REPORTGST', 
          children: [
            {
              type: 'link',
              label: 'GST Sales',
              visiable: true,
              activityID: '',
              route: '/reports/gstr1-report',
            }, 
            {
              type: 'link',
              label: 'GSTR1',
              visiable: true,
              activityID: '',
              route: '/reports/gstr1-detail-report',
            },          
          ]
        }, 
        {
          type: 'dropdown',
          label: 'MIS REPORT',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'REPORTMIS', 
          children: [
            {
              type: 'link',
              label: 'Day Book',
              visiable: false,
              activityID: '',
              route: '/reports/day-book-report',
            }, 
            {
              type: 'link',
              label: 'Day Book',
              visiable: false,
              activityID: 'REPORTDAYBOOK',
              route: '/reports/manual-rec-pay-report',
            },         
          ]
        }, 
      ]
    },

    {
      type: 'subheading',
      label: 'Master',
      visiable: false,
      activityID: 'MASTERBANK_C1',  
      children: [
        {
          type: 'dropdown',
          label: 'Bank',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'MASTERBANK_C1', 
          children: [
        {
          type: 'link',
          label: 'Bank-Master',
          route: '/pages/bank-master',
          icon: 'mat:label',
          visiable: false,
          activityID: 'MASTERBANK_C1'
        },
        {
          type: 'link',
          label: 'Bank-Transation',
          route: '/pages/bank-transation',
          icon: 'mat:label',
          visiable: false,
          activityID: 'TRAN_BANK_C1'
        },
      ],
    },


 
    {
      type: 'link',
      label: 'Department',
      route: '/pages/department',
      icon: 'mat:label',
      visiable: true,
      activityID: ''
    },
    {
      type: 'link',
      label: 'Designation',
      route: '/pages/designation',
      icon: 'mat:label',
      visiable: true,
      activityID: ''
    },
      ]
    },

    {
      type: 'subheading',
      label: 'Application',
      visiable: false,
      activityID: 'TRAN_ATTENDANCE_C1',  
      children: [
        {
          type: 'dropdown',
          label: 'Attendance',
          icon: 'mat:receipt',       
          visiable: false,
          activityID: 'TRAN_ATTENDANCE_C1', 
          children: [
        {
          type: 'link',
          label: 'Entry',
          route: '/pages/attendance-entry',
          icon: 'mat:label',
          visiable: false,
          activityID: 'TRAN_ATTENDANCE_C1'
        },
         
      ]
    }
      ]
    },



    {
      type: 'subheading',
      label: 'Settings',
      visiable: false,
      activityID: 'UTIL',
      children: [
        {
          type: 'dropdown',
          label: 'Application Settings',
          icon: 'mat:settings',  
          visiable: false,
          activityID: 'MASTER',      
          children: [
            {
              type: 'link',
              label: 'Organization',
              route: '/pages/organization',
              icon: 'mat:person',
              visiable: false,
              activityID: 'MASTERORGADD',
            },

            {
              type: 'dropdown',
              label: 'Vendor',
              icon: 'mat:receipt',       
              visiable: false,
              activityID: 'MASTERVENDORADD', 
              children: [
                {
                  type: 'link',
                  label: 'Entry',
                  visiable: false,
                  activityID: 'MASTERVENDORADD',
                  route: '/pages/vendor',
                },
                {
                  type: 'link',
                  label: 'View',
                  visiable: false,
                  activityID: 'MASTERVENDORVIEW',
                  route: '/pages/vendor-view',
                },            
              ]
            },  



            {
              type: 'link',
              label: 'Access Control',
              route: '/pages/access-control',
              icon: 'mat:person',
              visiable: false,
              activityID: 'UTILCOMPONENTRIGHTS',
            },  
            
            {
              type: 'dropdown',
              label: 'Employee',
              icon: 'mat:receipt',       
              visiable: true,
              activityID: '', 
              children: [
              {
                type: 'link',
                label: 'Entry',
                route: '/pages/employee',
                icon: 'mat:label',
                visiable: true,
                activityID: ''
              },
              {
                type: 'link',
                label: 'View',
                route: '/pages/employee/view',
                icon: 'mat:label',
                visiable: true,
                activityID: ''
              },
            ],
          },
            {
              type: 'link',
              label: 'User Profile',
              route: '/pages/user-profile',
              icon: 'mat:person',
              visiable: false,
              activityID: 'MASTERUSERPROFILE',
            },             
          ]
        },   
      ]
    }
  ];


  }
 
  public messagebox(heading: string, content: string, status: boolean) {
    const dialogRef = this.dialog.open(DialogResultComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '450px',
      disableClose: true,
      data: { heading: heading, content: content, error: status }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  

  async ngOnInit() {


    this.stockoutput = new Array<checkLoginoutput>();

    this.accountService.StockCatuser.subscribe(x => this.StockCatuser = x);
    if (this.StockCatuser === null) {
      this.router.navigate(['/login']);
      this.accountService.logout();
    }
    else {

        if (localStorage.getItem('user')) {
          this.userSubject = new BehaviorSubject<checkLoginoutput>(JSON.parse(localStorage.getItem('user')));
        } else {
          this.userSubject = new BehaviorSubject<checkLoginoutput>(JSON.parse(sessionStorage.getItem('user')));
        }

        // if (localStorage.getItem('MenuRights')) {
        //   this.menuSubject = new BehaviorSubject<MenuRights[]>(JSON.parse(localStorage.getItem('MenuRights')));
        //   this.Application.menurights=  this.menuSubject.value;        
        // }
        // else{
        //   await this.Application.GetMenuRights(this.Application.ouid, this.Application.userID, this.Application.roleID, this.Application.roleID).then(resp => {
        //     if (resp['isSuccess'] == false) {
        //       alert(resp['errorDesc']);
        //       return false;
        //     }
        //     this.Application.menurights = resp['result'];
        //     localStorage.setItem('MenuRights', JSON.stringify(this.Application.menurights));
        //   });
        // }
 
      let stockcatobj = this.StockCatuser['result'];
      environment.apiUrl = String(stockcatobj[0]['serviceURL']);

      this.accountService.user.subscribe(x => this.user = x);
      if (this.user === null) {
        this.router.navigate(['/login']);
        this.accountService.logout();
      }
      else {

        this.form = new checkLoginparam;
        this.form.UserName = this.userSubject.value.UserName;
        this.form.Password = this.userSubject.value.PassWord;
        this.form.MPIN = this.userSubject.value.MPIN;
        await this.Login.GetStockCatLogin(
          this.userSubject.value.UserName, 
          this.userSubject.value.PassWord, 
          this.userSubject.value.MPIN, 
          this.userSubject.value.remember).then(resp => {
            this.stockoutput = resp['result'];
          });
        await this.Login.Getall(this.form).then(resp => {
          this.checkresult = resp;
          this.userobj = resp['result'];
        });

        this.userobj = this.user['result'];
        if (this.userobj[0]['proceedLogin'] == true) {
          this.Application.username = this.userobj[0]['userDescription'];
          this.Application.ouid = this.userobj[0]['ouid'];
          this.Application.userID = this.userobj[0]['userID'];
          this.Application.roleID = this.userobj[0]['roleID'];
          this.Application.EmpID = this.userobj[0]['userEmpID'];
          this.Application.statecode = this.userobj[0]['ouStateCode'];
          this.Application.SubcriptionID = this.userobj[0]['subscriptionID'];
          this.Application.SubscriptionShortName = this.userobj[0]['subscriptionShortName'];
        } else {
          this.router.navigate(['/login']);
          this.accountService.logout();
        }
  
      }
    }

  }

}
