import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { environment } from 'src/environments/environment';

import { MenuRights } from '../common-interface/menu-rights.module';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  username: string;
  ouid: number;
  userID: number;
  roleID: number;
  EmpID: number;
  statecode: String;
  loginouid: String;
  SubcriptionID: number;
  SubscriptionShortName: String;
  Gender: string = 'M';
  Maritalstatus: string = '1';
  CurrentTime: any;
  public menurights: MenuRights[] = [];
  public ouname = '';
  public ouaddress = '';
  public ouEmail = '';
  public ouPhone1 = '';
  public ouPhone2 = '';
  constructor(public http: HttpClient) {
    this.username = '';
    this.ouid = 0;
    this.userID = 0;
    this.roleID = 0;
    this.EmpID = 0;
    this.statecode = '';
    this.SubcriptionID = 0;
    this.SubscriptionShortName = '';
    this.menurights = [];
    setInterval(() => {
      this.CurrentTime = this.format(new Date(), 'input') + '     ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
    }, 1);

  }


  public GetSendSMS(LoginOUID, LoginUserId, LoginRoleId, OUID): Observable<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", LoginOUID).set("LoginUserID", LoginUserId).set("LoginRoleID", LoginRoleId).set("OUID", OUID);
    return this.http.get<object>(environment.apiUrl + "api/SendSMS", { params: params });
  }

  async GetMenuRights(OUID, UserId, RoleId, RoleID): Promise<Object[]> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", OUID).set("LoginUserID", UserId).set("LoginRoleID", RoleId).set("RoleID", RoleID);
    return this.http.get<Object[]>(environment.apiUrl + "api/Rights", { params: params }).toPromise();

  }

  public LoadCheck_Numeric(OUID, UserId, RoleId, CheckboxType): Observable<Object[]> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", OUID).set("LoginUserID", UserId).set("LoginRoleID", RoleId).set("CheckboxType", CheckboxType);

    return this.http.get<Object[]>(environment.apiUrl + "api/CombofillNonNumeric", { params: params });
  }

  async Loadcombo_Numeric(OUID, UserId, RoleId, ComboType): Promise<Object[]> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", OUID).set("LoginUserID", UserId).set("LoginRoleID", RoleId).set("ComboType", ComboType);
    return this.http.get<Object[]>(environment.apiUrl + "api/CombofillNumeric", { params: params }).toPromise();
  }




  public Loadcombo_Numeric_Filter(OUID, UserId, RoleId, ComboType, FilterInput): Observable<Object[]> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", OUID).set("LoginUserID", UserId).set("LoginRoleID", RoleId).set("ComboType", ComboType).set("FilterInput", FilterInput);
    return this.http.get<Object[]>(environment.apiUrl + "api/CombofillNumeric", { params: params });
  }

  public Loadcombo_Numeric_Filter2(OUID, UserId, RoleId, ComboType, FilterInput, FilterInput2): Observable<Object[]> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", OUID).set("LoginUserID", UserId)
      .set("LoginRoleID", RoleId).set("ComboType", ComboType).set("FilterInput", FilterInput).set("FilterInput2", FilterInput2);
    return this.http.get<Object[]>(environment.apiUrl + "api/CombofillNumeric", { params: params });
  }

  async Loadcombo_NonNumeric(OUID, UserId, RoleId, ComboType): Promise<Object[]> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", OUID).set("LoginUserID", UserId).set("LoginRoleID", RoleId).set("ComboType", ComboType);
    return this.http.get<Object[]>(environment.apiUrl + "api/CombofillNonNumeric", { params: params }).toPromise();
  }

  public uploadAndProgress(files: File): Observable<object> {
    var formData = new FormData();
    formData.append('file', files)
    return this.http.post<object>(environment.apiUrl + 'api/FileBuffer', formData);
  }

  async GetOrgunit(LoginOUID, LoginUserId, LoginRoleId, OUID): Promise<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", LoginOUID).set("LoginUserID", LoginUserId).set("LoginRoleID", LoginRoleId).set("OUID", OUID);
    return this.http.get<object>(environment.apiUrl + "api/OrganizationUnit", { params: params }).toPromise();
  }

  public GetDistrictDetail(LoginOUID, LoginUserId, LoginRoleId, districtID): Observable<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", LoginOUID).set("LoginUserID", LoginUserId).set("LoginRoleID", LoginRoleId).set("districtID", districtID);
    return this.http.get<object>(environment.apiUrl + "api/DistrictSearchHelper", { params: params });
  }

  public GetDistrictSearch(LoginOUID, LoginUserId, LoginRoleId, StateCode): Observable<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", LoginOUID).set("LoginUserID", LoginUserId).set("LoginRoleID", LoginRoleId).set("StateCode", StateCode);
    return this.http.get<object>(environment.apiUrl + "api/DistrictSearchHelper", { params: params });
  }

  public GetTalukSearch(LoginOUID, LoginUserId, LoginRoleId, districtID): Observable<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", LoginOUID).set("LoginUserID", LoginUserId).set("LoginRoleID", LoginRoleId).set("DistrictID", districtID);
    return this.http.get<object>(environment.apiUrl + "api/TalukSearchHelper", { params: params });
  }

  public GetVillageSearch(LoginOUID, LoginUserId, LoginRoleId, TalukID): Observable<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", LoginOUID).set("LoginUserID", LoginUserId).set("LoginRoleID", LoginRoleId).set("TalukID", TalukID);
    return this.http.get<object>(environment.apiUrl + "api/VillageSearchHelper", { params: params });
  }

  public GetReferralAgentSearch(LoginOUID, LoginUserId, LoginRoleId, OUID): Observable<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", LoginOUID).set("LoginUserID", LoginUserId).set("LoginRoleID", LoginRoleId).set("OUID", OUID);
    return this.http.get<object>(environment.apiUrl + "api/ReferralAgentSearchHelper", { params: params });
  }

  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  format(date: Date, displayFormat: string): string {
    if (displayFormat == "input") {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
    } else if (displayFormat == "inputMonth") {
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return this._to2digit(month) + '/' + year;
    } else {
      return date.toDateString();
    }
  }
  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }



}

export class ComboResult {
  data: Combodet;
  default: string;
}

export class Combodet {
  code: string;
  description: string;
}



export class Checkboxdet {
  code: number;
  description: string;
  isSelected: boolean;
}



export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;
  parse(value: any): Date | null {
    if (typeof value === "string" && value.indexOf("/") > -1) {
      const str = value.split("/");
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}