import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationItem, NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';



import { ApplicationService } from 'src/app/common-service/application.service';
import { MenuRights } from './../../../app/common-interface/menu-rights.module';
import { tr } from 'date-fns/locale';
import { NgxSpinnerService } from 'ngx-spinner';
import { checkLoginDetails, checkLoginoutput } from 'src/app/pages/pages/auth/login/login.component';
import { AccountService } from 'src/app/common-service/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));

  userMenuOpen$: Observable<boolean> = of(false);
  items:NavigationItem[] = [];
  //items = this.navigationService.items;

  menuRights: MenuRights[] = [];

  username: string;
  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              public Application: ApplicationService,
              private readonly popoverService: PopoverService,
              private readonly dialog: MatDialog,
              private accountService: AccountService,
              private router: Router,
              private spinner: NgxSpinnerService) { 
                
                // this.loadMenuRights();   

              }

  ngOnInit() {

     this.loadMenuRights();   

  }
            
  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          }
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    });
  }
  user: checkLoginDetails;
  userlogin: checkLoginoutput;
  mainmenu: boolean = false
  async loadMenuRights() {

   let data 
    if (this.Application.ouid == 0) {
      if (localStorage.getItem('user')) {
         data = new BehaviorSubject<object>(JSON.parse(localStorage.getItem('user')));
      } else {
         data  = new BehaviorSubject<object>(JSON.parse(sessionStorage.getItem('user')));
      }
  
      this.user =data.value.result[0];
      if (this.user.ouid == 0) {
        this.router.navigate(['/login']);
        this.accountService.logout();
        return false;
      }
      this.Application.ouid =this.user.ouid;
      this.Application.userID=this.user.userID;
      this.Application.roleID=this.user.roleID;
       this.username=this.user.userDescription;
    } 

    //await this.Application.GetMenuRights(OUID, userID,roleID, roleID).then(resp => {
   await this.Application.GetMenuRights(this.Application.ouid, this.Application.userID, this.Application.roleID, this.Application.roleID).then(resp => {
   // await this.Application.GetMenuRights(1, 1, 1, 1).then(resp => {
      if (resp['isSuccess'] == false) {
        alert(resp['errorDesc']);
        return false;
      }
      this.Application.menurights = resp['result']; 
      this.Application.menurights.forEach((menu) => {
        this.navigationService.items.forEach((items) => {                     
          if (items.type == 'subheading') {
            let _subheading = items;
            this.mainmenu = false
            if (items.activityID == menu.activityID) {
              items.visiable = menu.visibleRights;
            }
            _subheading.children.forEach((subItem) => {
              if (subItem.activityID == menu.activityID) {
                subItem.visiable = menu.visibleRights;
              }
              if (subItem.type == 'dropdown') {
                let _childrenItem = subItem;
                _childrenItem.children.forEach((childrenItem) => {

                    if (childrenItem.activityID == menu.activityID) {
                      childrenItem.visiable = menu.visibleRights;
                    }
                    if (childrenItem.type == 'dropdown') {
                      let _childrensubItem = childrenItem;
                      this.mainmenu = false

                      _childrensubItem.children.forEach((childrensubItem) => {                                 
                        if (childrensubItem.activityID == menu.activityID) {
                          childrensubItem.visiable = menu.visibleRights;
                        }
                        if (childrensubItem.visiable == true) {
                          this.mainmenu = true
                        }
                      });
                    }

                });
              } 
            });  
            
            
                // _subheading.children.forEach((subItem) => {

                //    let subitemvisible = subItem.visiable;
                //   // if (subItem.activityID == menu.activityID) {
                //   //   subItem.visiable = menu.visibleRights;
                //   // }
                //   if (subItem.type == 'dropdown') {
                //     let _childrenItem = subItem;
                //     this.mainmenu = false

                //     // if (subItem.activityID == menu.activityID) {
                //     //   subItem.visiable = menu.visibleRights;
                //     // }

                //     _childrenItem.children.forEach((childrenItem) => {
                //       if (_subheading.activityID != '') {
                //         if (childrenItem.activityID == menu.activityID) {
                //           childrenItem.visiable = menu.visibleRights;
                //         }
                //         if (childrenItem.type == 'dropdown') {
                //           let _childrensubItem = childrenItem;
                //           this.mainmenu = false

                //           _childrensubItem.children.forEach((childrensubItem) => {                                 
                //             if (childrensubItem.activityID == menu.activityID) {
                //               childrensubItem.visiable = menu.visibleRights;
                //             }
                //             if (childrensubItem.visiable == true) {
                //               this.mainmenu = true
                //             }
                //           });
                //           childrenItem.visiable = this.mainmenu
                //         }
                //         if (childrenItem.visiable == true) {
                //           this.mainmenu = true
                //           subItem.visiable = true
                //         }
                //       }
                //     });
                //     subItem.visiable = subitemvisible                  
                //   }          
                //   // _subheading.visiable = this.mainmenu
                // });
        
            }
        });
      });

      this.items = this.navigationService.items;
    });
  }
}
