import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';


import { first } from 'rxjs/operators';
import { ApplicationService } from 'src/app/common-service/application.service';
import { AccountService } from 'src/app/common-service/account.service';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

export class checkLoginresult {
  isSuccess: boolean;
  errorcode: string;
  errorDesc: string;
  result: checkLoginoutput[];
}

export class StockCatcheckLoginoutput {
  Status: string;
  ProceedLogin: string;
  serviceURL: string;
}

export class checkLoginoutput {
  Status: string;
  UserName: string;
  remember: boolean;
  MPIN: number = 0;
  PassWord: string;
  ProceedLogin: string;
  OUID: number;
  UserID: number;
  RoleID: number;
  UserDescription: string;
 
}
export class checkLoginDetails {
ouStateCode: string;
ouid: number;
proceedLogin: boolean;
roleID: number;
status: string;
userDPPath: string;
userDescription: string;
userEmpID: number;
userID: number;
}

export class PrintSelection {
  columns: [];
  option: string;
  user: number;
}



export class checkLoginparam {
  UserName: string;
  Password: string;
  MPIN: number = 0;
}


@Component({
  selector: 'vex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ]
})
export class LoginComponent implements OnInit {

  
  form: FormGroup;

  checkresult: checkLoginresult[] = [];

  checkoutput: Array<checkLoginoutput>;
  stockoutput: Array<checkLoginoutput>;
  validlogin: boolean;
  inputType = 'password';
  visible = false;
  failed: boolean = false;

  public checkLogin: checkLoginparam = new checkLoginparam();

  

  error: boolean = false;

  constructor(
    public Login: LoginService,private router: Router,
              private fb: UntypedFormBuilder,
              private cd: ChangeDetectorRef,
              private snackbar: MatSnackBar,
              public Application: ApplicationService,
              private accountService: AccountService
  ) {

    this.error = false;
    if (this.accountService.userValue) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      MPIN: [0],
      remember: [false]
    });
  }

  //send() {
    //this.router.navigate(['/']);
    //this.snackbar.open('Lucky you! Looks like you didn\'t need a password or email address! For a real application we provide validators to prevent this. ;)', 'LOL THANKS', {
      //duration: 10000
   // });
  //}


  get f() { return this.form.controls; }


  async send(form: FormGroup) {
    // this.router.navigate(['/']);

    await this.Login.GetStockCatLogin(this.f.username.value, this.f.password.value, this.f.MPIN.value, this.f.remember.value).then(resp => {
      this.stockoutput = resp['result'];
    });

    if (this.stockoutput[0]['proceedLogin'] == true) {

      environment.apiUrl = String(this.stockoutput[0]['serviceURL']);
      await this.Login.Getall(form.value).then(resp => {

        this.checkresult = resp;
        this.checkoutput = resp['result'];
      });

      this.accountService.login(this.f.username.value, this.f.password.value, this.f.MPIN.value, this.f.remember.value)
        .pipe(first())
        .subscribe();
      if (this.checkoutput[0]['proceedLogin'] == true) {
        this.error = false;
        this.router.navigate(['/']);
        this.Application.username = this.checkoutput[0]['userDescription'];
        this.Application.ouid = this.checkoutput[0]['ouid'];
        this.Application.userID = this.checkoutput[0]['userID'];
        this.Application.roleID = this.checkoutput[0]['roleID'];
        this.Application.EmpID = this.checkoutput[0]['userEmpID'];
        this.Application.statecode = this.checkoutput[0]['ouStateCode'];
        this.Application.SubcriptionID = this.checkoutput[0]['subscriptionID'];
        this.Application.SubscriptionShortName = this.checkoutput[0]['subscriptionShortName'];
      } else {
        this.error = true;
        this.form.get('password').setErrors({ 'Invalid User/Password...!': 'error' });
      }
    } else {
      this.error = true;
      this.form.get('password').setErrors({ 'Invalid User/Password...!': 'error' });
    }
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }
}
