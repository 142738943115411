 
 <form (ngSubmit)="save()" [formGroup]="entryform">
    <div class="flex-auto px-2 mt-2" fxLayout="column">
        <div class="px-6 py-4 border-b flex items-center">                        
            <h2 class="title mt-2 flex items-center">
                <span @scaleIn class="w-10 h-10 rounded-full text-primary mr-3 flex items-center justify-center bg-primary/10">
                <mat-icon class="icon-sm" svgIcon="mat:description"></mat-icon>
                </span>
                <span @fadeInRight class="block">Appointment Entry</span>
            </h2>
        </div>
        <div class="px-2 mt-6" fxLayout="column">

            <div class="flex flex-col sm:flex-row">
                <mat-form-field class="flex-auto" appearance="outline" required>
                    <mat-label class="text-primary">Customer Name</mat-label>
                    <input formControlName="firstName" class="text-primary"
                    cdkFocusInitial matInput
                    required autocomplete="off">
                </mat-form-field>
            </div>
            <mat-form-field class="flex-auto" appearance="outline">
                <mat-label class="text-primary">Phone Number</mat-label>
                <input (keypress)="numberOnly($event)" maxlength="15" minlength="10"
                formControlName="personalContactNo" matInput required autocomplete="off">
            </mat-form-field>

            <div  class=" mt-2 flex-auto text-primary"  >
                <a mat-button class="text-primary" (click)="addressToggleDropdown()"> + Add Address (Optional) </a>  
            </div>
            <div   class="flex flex-col ">
                <div class="bg-primary/10  border w-full text-hint overflow-hidden bg-foreground flex-auto flex items-center"> 
                    <p  class="ml-2 font-medium text-hint"> Address (Optional)  </p>   
                    <span fxFlex></span>
                    <button mat-button color="accent"  (click)="addressToggleDropdown()">Remove</button>
                </div>
                <div class="mt-3 flex-auto" fxLayout="row">
                    <div class="flex-auto"  fxLayout="column">

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Flat,HouseNo</mat-label>
                            <input formControlName="residential_AddressLine1" matInput autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Street,Area,Sector,Village</mat-label>
                            <input formControlName="residential_AddressLine2" matInput autocomplete="off">
                        </mat-form-field>    

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Landmark</mat-label>
                            <input formControlName="residential_Landmark" matInput autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Pincode</mat-label>
                            <input (keypress)="numberOnly($event)" maxlength="6"
                            formControlName="residential_Pincode"  matInput autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Town,City</mat-label>
                            <input formControlName="residential_City" matInput autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Select State / Province / Region</mat-label>
                            <mat-select (selectionChange)="OnChangeleadState()"
                                formControlName="residential_Statecode" matselect  autocomplete="off">
                                <mat-option *ngFor="let _state of combo_state" [value]="_state.code">
                                {{ _state.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div *ngIf="!entryform.get('permanentSameAsResidential').value"  class="ml-4 flex-auto"  fxLayout="column">

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Flat,HouseNo</mat-label>
                            <input formControlName="permanent_AddressLine1" matInput autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Street,Area,Sector,Village</mat-label>
                            <input formControlName="permanent_AddressLine2" matInput autocomplete="off">
                        </mat-form-field>    

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Landmark</mat-label>
                            <input formControlName="permanent_Landmark" matInput autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Pincode</mat-label>
                            <input (keypress)="numberOnly($event)" maxlength="6"
                            formControlName="permanent_Pincode"  matInput autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Town,City</mat-label>
                            <input formControlName="permanent_City" matInput autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="flex-auto" appearance="outline">
                            <mat-label class="text-primary">Select State / Province / Region</mat-label>
                            <mat-select (selectionChange)="OnChangeleadState()"
                                formControlName="permanent_Statecode" matselect  autocomplete="off">
                                <mat-option *ngFor="let _state of combo_state" [value]="_state.code">
                                {{ _state.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                </div>
                <mat-checkbox tabindex="-1"  #taxcalc color="primary"  [checked]="true"
                    formControlName="permanentSameAsResidential"
                    (change)='shipToggleDropdown()' class="checkbox"> Shipping address same as billing address
                </mat-checkbox>
            </div>
            <div *ngIf="!addGst"  class=" mt-2 flex-auto text-primary"  >
                <a mat-button class="text-primary" (click)="gstToggleDropdown()"> + Add GSTIN (Optional) </a>  
            </div>

            <div  *ngIf="addGst" class="flex flex-col ">
                <div class="bg-accent/10  border w-full text-hint overflow-hidden bg-foreground flex-auto flex items-center"> 
                    <p  class="ml-2 font-medium text-hint"> GSTIN (Optional)  </p>   
                    <span fxFlex></span>
                    <button mat-button color="accent"  (click)="gstToggleDropdown()">Remove</button>
                </div>

                <mat-form-field class="mt-3 flex-auto" appearance="outline">
                    <mat-label class="text-primary">GSTIN</mat-label>
                    <input formControlName="gstIN" matInput autocomplete="off">
                </mat-form-field>
            </div>
        </div>
    </div>
 
</form>