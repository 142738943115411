<div class="footer flex">
  <div class="flex-auto flex items-center container">
    <a class="action flex-none"
       color="primary"
       href="https://Benzosoft.com"
       id="get-vex"
       mat-flat-button>
      <mat-icon class="icon-sm ltr:mr-2 rtl:ml-2" svgIcon="mat:shopping_bag"></mat-icon>
      <span>GST Billing Software</span>
    </a>
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      Copyright &copy;{{ myDate }} All rights reserved
    </div>
  </div>
</div>
