  
  <div class="ml-1" mat-dialog-title fxLayout ="row">
    <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon *ngIf="error" svgIcon="mat:error" class="text-secondary flex-auto" ></mat-icon>
          <mat-icon *ngIf="!error" svgIcon="mat:check_circle" class="text-secondary flex-auto"></mat-icon> 
          <h1  class="flex-auto ml-2">  {{heading}}</h1>
    </div>
  </div>  
 
  <div  class="ml-3 flex-auto" >
    <mat-label class="body-2 m-0 leading-snug">{{content}}</mat-label>               
    <h5 class="text-secondary m-0 caption leading-none">{{ content1 }} </h5>
    <h5 class="text-secondary m-0 caption leading-none">{{ content2 }} </h5>
  </div>
  
  <mat-dialog-actions align="end">  
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>