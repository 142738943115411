<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>
    <img *ngIf="form.get('imageSrc').value" [src]="form.get('imageSrc').value" class="avatar mr-5">

    <h2 *ngIf="form.get('firstName').value || form.get('lastName').value"
        class="headline m-0 flex-auto">{{ form.get('firstName').value + ' ' + form.get('lastName').value }}</h2>
    <h2 *ngIf="!form.get('firstName').value && !form.get('lastName').value"
        class="headline m-0 flex-auto">New Customer</h2>

    <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>First Name</mat-label>
        <input cdkFocusInitial formControlName="firstName" matInput>

        <mat-icon matPrefix svgIcon="mat:person"></mat-icon>
      </mat-form-field>

      <mat-form-field class="sm:ml-6 flex-auto">
        <mat-label>Last Name</mat-label>
        <input formControlName="lastName" matInput>

        <mat-icon matPrefix svgIcon="mat:person"></mat-icon>
      </mat-form-field>
    </div>

    <mat-form-field class="flex-auto">
      <mat-label>Street</mat-label>
      <input formControlName="street" matInput>

      <mat-icon matPrefix svgIcon="mat:edit_location"></mat-icon>
    </mat-form-field>

    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Zipcode</mat-label>
        <input formControlName="zipcode" matInput>

        <mat-icon matPrefix svgIcon="mat:my_location"></mat-icon>
      </mat-form-field>

      <mat-form-field class="sm:ml-6 flex-auto">
        <mat-label>City</mat-label>
        <input formControlName="city" matInput>

        <mat-icon matPrefix svgIcon="mat:location_city"></mat-icon>
      </mat-form-field>
    </div>

    <mat-form-field class="flex-auto">
      <mat-label>Phone Number</mat-label>
      <input formControlName="phoneNumber" matInput>

      <mat-icon matPrefix svgIcon="mat:phone"></mat-icon>
    </mat-form-field>

    <mat-form-field class="flex-auto">
      <mat-label>Notes</mat-label>
      <textarea formControlName="notes" matInput></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit">Create Customer</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit">Update Customer</button>
  </mat-dialog-actions>
</form>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Print</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Export</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
