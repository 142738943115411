import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'; 
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Compactcustomer } from '../../../../common-interface/customercompact-interface.module';
import { ApplicationService, Combodet } from '../../../../common-service/application.service'
import { LoaderService } from '../../../../common-service/loader.service'

import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { fadeInRight400ms } from '../../../../../@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from '../../../../../@vex/animations/scale-in.animation';
import { stagger40ms } from '../../../../../@vex/animations/stagger.animation';
import { stagger80ms } from '../../../../../@vex/animations/stagger.animation';
import { QuickCustomerEntryService } from './add-appointment-entry.service';
import { optionscustomer } from './interface.module';
import { Observable } from 'rxjs';
import { Cusaddress } from 'src/app/common-interface/customer-mini-interface.module';

import { debounceTime, filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'vex-add-appointment-entry',
  templateUrl: './add-appointment-entry.component.html',
  styleUrls: ['./add-appointment-entry.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger80ms,
    stagger40ms
  ]
})

export class QuickCustomerEntryComponent implements OnInit {
  
  entryform: UntypedFormGroup; 
  mode: 'create' | 'update' = 'create';

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  error: boolean = false;
  combotype: any;
  combo_state: Combodet[]=[];
  compactcustomer: Compactcustomer;

  constructor(
      public fb: UntypedFormBuilder,
      public Application: ApplicationService,
      public cd: ChangeDetectorRef,
      public LoaderService: LoaderService,
      public service: QuickCustomerEntryService,
  ) { this.error = false; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;    
  }

  ngOnInit(): void {    
 

    
    this.entryform = this.fb.group({
      customerID:   0 ,
      firstName: '',
      lastName: '',
      personalContactNo:  '',
      personalEmail:  '',
      residentialAddressID:  0,
      residential_AddressLine1:  '',
      residential_AddressLine2:   '',
      residential_Landmark:   '',
      residential_City: '',
      residential_Pincode:  '',
      residential_Statecode:  this.Application.statecode ,
      stateName:  '',
      permanentSameAsResidential:  true,
      permanentAddressID:   0,
      permanent_AddressLine1:  '',
      permanent_AddressLine2:  '',
      permanent_Landmark:  '',
      permanent_City:  '',
      permanent_Pincode:  '',
      permanent_Statecode:   this.Application.statecode,
      gstIN:   ''
    });
    this.loadState();
    this.loadCustomerSearch();
   
 
    this.refreshPage();
  }

  OnChangeleadState() {
    // let state = this.combo_state.filter(Status => Status.code === this.entryform.get('residential_Statecode').value);
    // this.entryform.patchValue({ stateName: state[0].description });
  }

  customerdata: optionscustomer[] = [];
  filteredCustomer: Observable<optionscustomer[]>;
  customeraddress: Cusaddress;
  customersearch = new UntypedFormControl();

  customerDisplayFn(data?: any): string | undefined {
    return data ? data.customerName : undefined;
  }

  private _Customerfilter(value: any): optionscustomer[] {
    const filterValue = value.toLowerCase();
    return this.customerdata.filter((s) => new RegExp(filterValue, 'gi').test(s.searchColumn));
  }
 
  loadCustomerSearch() {
    this.service.GetCustomerSearch(this.Application.ouid, this.Application.userID, this.Application.roleID, this.Application.ouid).subscribe(resp => {
      this.customerdata = resp['result'];
      this.filteredCustomer = this.entryform.valueChanges.pipe(
        startWith<string | any>(''),
        map(value => (typeof value === "string" ? value : value.searchColumn)),
        map(name => (name ? this._Customerfilter(name) : this.customerdata.slice())),
      );
      this.customerDisplayFn(this.customerdata[0]);
      this.getCustomer('');
    });
  }

  getCustomer(Search: string) {
    this.filteredCustomer = this.entryform.valueChanges
      .pipe(
        startWith(''),
        map(value => this._Customerfilter(Search).slice(0, 20))
      );
  }

  loadCustomerDetail() {
    let data = this.customersearch.value;
    if (data) {
      this.service.GetCustomerDetail(
        this.Application.ouid, this.Application.userID, this.Application.roleID, data.customerID).then(resp => {
        let custdata = resp['result'];   
        console.log(custdata);
        this.entryform.patchValue({
          customerID: custdata.customerID || '',
          firstName: custdata.firstName || '',
          lastName:  custdata.lastName || '',
          personalContactNo: custdata.personal.personalContactNo || '',
          personalEmail: custdata.personal.personalEmail || '',
          residentialAddressID: custdata.personal.residentialAddressID || 0,
          residential_AddressLine1: custdata.personal.residential_AddressLine1 || '',
          residential_AddressLine2:  custdata.personal.residential_AddressLine2 || '',
          residential_Landmark: custdata.personal.residential_Landmark || '',
          residential_City: custdata.personal.residential_City || '',
          residential_Pincode: custdata.personal.residential_Pincode || '',
          residential_Statecode:  custdata.personal.residential_Statecode|| this.Application.statecode ,
          permanentSameAsResidential: custdata.personal.permanentSameAsResidential ,
          permanentAddressID: custdata.personal.permanentAddressID || 0,
          permanent_AddressLine1: custdata.personal.permanent_AddressLine1 || '',
          permanent_AddressLine2:  custdata.personal.permanent_AddressLine2 || '',
          permanent_Landmark: custdata.personal.permanent_Landmark || '',
          permanent_City: custdata.personal.permanent_City || '',
          permanent_Pincode: custdata.personal.permanent_Pincode || '',
          permanent_Statecode:  custdata.personal.permanent_Statecode|| this.Application.statecode ,
          stateName: custdata.stateName || '',
          gstIN: custdata.verification.gstin || '' ,
        });

        this.refreshPage();

      });
    }
  }

  refreshPage()
  {
    this.addAddress = false;
    this.addGst = false;
    if (this.entryform.get('gstIN').value != '') { this.addGst =true;}
    if (this.entryform.get('residential_AddressLine1').value != ''){
      this.addAddress = true;
      this.resize();
      this.cd.markForCheck();
    }
  }
    
  addAddress = true;
  addressToggleDropdown() {
    this.addAddress = !this.addAddress;   
    if (!this.addAddress){
      this.entryform.patchValue({ permanentSameAsResidential: true});
    } 
    this.resize()
    this.cd.markForCheck();
  } 
  
 
  resize(){
    // if (this.entryform.get('permanentSameAsResidential').value == true){
    //   this.dialogRef.updateSize('760px');
    // }
    // else{
    //   this.dialogRef.updateSize('920px');
    // }
  }

  shipToggleDropdown() {
    this.resize()
    this.cd.markForCheck();
    this.shiptoAddress_Change();
  }

  shiptoAddress_Change(){
    const creds = this.entryform as UntypedFormGroup;
    if (this.entryform.get('permanentSameAsResidential').value == true) {
      this.entryform.patchValue({ permanent_AddressLine1: creds.value['residential_AddressLine1'].toString() });
      this.entryform.patchValue({ permanent_AddressLine2: creds.value['residential_AddressLine2'].toString() });
      this.entryform.patchValue({ permanent_Landmark: creds.value['residential_Landmark'].toString() });
      this.entryform.patchValue({ permanent_City: creds.value['residential_City'].toString() });
      this.entryform.patchValue({ permanent_Pincode: creds.value['residential_Pincode'].toString() });
      this.entryform.patchValue({ permanent_Statecode: creds.value['residential_Statecode'].toString() });
    }
    else {
      this.entryform.patchValue({ permanent_AddressLine1: '' });
      this.entryform.patchValue({ permanent_AddressLine2: '' });
      this.entryform.patchValue({ permanent_Landmark: '' });
      this.entryform.patchValue({ permanent_City: '' });
      this.entryform.patchValue({ permanent_Pincode: '' });
      this.entryform.patchValue({ permanent_Statecode: this.Application.statecode });
    }
  }

  addGst = false;
  gstToggleDropdown() {
    this.addGst = !this.addGst;
    this.cd.markForCheck();
  }

  loadState() {
    this.combotype = 'STATE';
    this.Application.Loadcombo_NonNumeric(this.Application.ouid, this.Application.userID, this.Application.roleID, this.combotype).then(resp => {
      this.combo_state = resp['result'].data;
      this.OnChangeleadState();
    });
  }

  save() {
    const creds = this.entryform as UntypedFormGroup;
    if (this.entryform.get('permanentSameAsResidential').value) {
      this.entryform.patchValue({ permanent_AddressLine1: creds.value['residential_AddressLine1'].toString() });
      this.entryform.patchValue({ permanent_AddressLine2: creds.value['residential_AddressLine2'].toString() });
      this.entryform.patchValue({ permanent_Landmark: creds.value['residential_Landmark'].toString() });
      this.entryform.patchValue({ permanent_City: creds.value['residential_City'].toString() });
      this.entryform.patchValue({ permanent_Pincode: creds.value['residential_Pincode'].toString() });
      this.entryform.patchValue({ permanent_Statecode: creds.value['residential_Statecode'].toString() });
    }
    this.createCustomer();
  }

  createCustomer() {
    const comcustomer = this.entryform.value;   
    event.preventDefault();
 
  }

  // updateeCustomer() {
  //   const comcustomer = this.entryform.value;
  //   this.dialogRef.close(comcustomer);
  // }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  validateEMAIL(event: string) {
    this.error = false;
    var regexp = new RegExp(this.emailPattern);
    var regex = regexp;
    if (regex.test(event)) {
      this.error = false;
      return true;
    } else if (event !== "") {
      this.error = true;
      this.entryform.get('personalEmail').setErrors({ 'Invalid Email...!': 'error' });
      return false;
    }
  }
}
