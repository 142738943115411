import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { checkLoginoutput, StockCatcheckLoginoutput } from 'src/app/pages/pages/auth/login/login.component';
import { environment } from '../../environments/environment';
import { ApplicationService } from './application.service';
import { MenuRights } from '../common-interface/menu-rights.module';
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private userSubject: BehaviorSubject<checkLoginoutput>;
  public user: Observable<checkLoginoutput>;
  public menudata: Observable<MenuRights[]>;


  private StockCatuserSubject: BehaviorSubject<StockCatcheckLoginoutput>;
  public StockCatuser: Observable<StockCatcheckLoginoutput>;


  constructor(
    public Application: ApplicationService,
    private router: Router,
    private http: HttpClient
  ) {
    if (localStorage.getItem('user')) {
      this.userSubject = new BehaviorSubject<checkLoginoutput>(JSON.parse(localStorage.getItem('user')));
    } else {
      this.userSubject = new BehaviorSubject<checkLoginoutput>(JSON.parse(sessionStorage.getItem('user')));
    }
    this.user = this.userSubject.asObservable();

    if (localStorage.getItem('StockCatuser')) {
      this.StockCatuserSubject = new BehaviorSubject<StockCatcheckLoginoutput>(JSON.parse(localStorage.getItem('StockCatuser')));
    } else {
      this.StockCatuserSubject = new BehaviorSubject<StockCatcheckLoginoutput>(JSON.parse(sessionStorage.getItem('StockCatuser')));
    }

    this.StockCatuser = this.StockCatuserSubject.asObservable();
  }

  public get userValue(): checkLoginoutput {
    return this.userSubject.value;
  }


  // async loadMenuRights() {
  //   if (this.Application.ouid !== 0)
  //     await this.Application.GetMenuRights(this.Application.ouid, this.Application.userID, this.Application.roleID, this.Application.roleID).then(resp => {
  //       if (resp['isSuccess'] == false) {
  //         alert(resp['errorDesc']);
  //         return false;
  //       }
  //       this.Application.menurights = resp['result'];
  //     });
  // }

  StockCatlogin(username, password, MPIN, remember) {
    let params = new HttpParams().set("UserName", username).set("Password", password).set("MPIN", MPIN);
    return this.http.get<StockCatcheckLoginoutput>(`${environment.benzosoftapiUrl}api/CheckBenzoLogin`, { params: params })
      .pipe(map(StockCatuser => {
        // store StockCatuser details and jwt token in local storage to keep StockCatuser logged in between page refreshes
        if (remember == true) {
          if (sessionStorage.getItem('StockCatuser')) {
            sessionStorage.removeItem('StockCatuser');
          }
          localStorage.setItem('StockCatuser', JSON.stringify(StockCatuser));
        } else {
          if (localStorage.getItem('StockCatuser')) {
            localStorage.removeItem('StockCatuser');
          }
          sessionStorage.setItem('StockCatuser', JSON.stringify(StockCatuser));
        }
        this.StockCatuserSubject.next(StockCatuser);
        return StockCatuser;
      }));

  }

  login(username, password, MPIN, remember) {
    return this.http.post<checkLoginoutput>(`${environment.apiUrl}api/CheckLogin`, { username, password, MPIN })
      .pipe(map(user => {
        user.UserName = username;
        user.PassWord = password;
        user.remember = remember;
        user.MPIN = MPIN;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (remember == true) {
          if (sessionStorage.getItem('user')) {
            sessionStorage.removeItem('user');
          }
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          if (localStorage.getItem('user')) {
            localStorage.removeItem('user');
          }
          sessionStorage.setItem('user', JSON.stringify(user));
        }
        this.userSubject.next(user);
        return user;
      }));
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }


}
