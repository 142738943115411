
export class Compactcustomer{
  customerID: number;
  entryDate: string= '';
  firstName: string;
  lastName:string;
  personalContactNo: string;
  personalEmail: string;
  residentialAddressID: number = 0;
  residential_AddressLine1: string;
  residential_AddressLine2:  string;
  residential_Landmark: string;
  residential_City: string;
  residential_Pincode: string;
  residential_Statecode: string;
  stateName: string;
  permanentSameAsResidential: boolean = false;
  permanentAddressID: number = 0;
  permanent_AddressLine1: string = '';
  permanent_AddressLine2: string = '';
  permanent_Landmark: string = '';
  permanent_City: string = '';
  permanent_Pincode: string = '';
  permanent_Statecode: string = '';
  gstIN: string = '';
  constructor(data) {
    this.customerID = data.customerID;
    this.entryDate =  data.entryDate;
    this.firstName = data.firstName;
    this.lastName=data.lastName;
    this.personalContactNo=data.personalContactNo;
    this.personalEmail=data.personalEmail;
    this.residentialAddressID=data.residentialAddressID;
    this.residential_AddressLine1=data.residential_AddressLine1;
    this.residential_AddressLine2=data.residential_AddressLine2;
    this.residential_Landmark=data.residential_Landmark;
    this.residential_City=data.residential_City;
    this.residential_Pincode=data.residential_Pincode;
    this.residential_Statecode=data.residential_Statecode;
    this.stateName=data.stateName;
    this.permanentSameAsResidential=data.permanentSameAsResidential;
    this.permanentAddressID=data.permanentAddressID;
    this.permanent_AddressLine1=data.permanent_AddressLine1;
    this.permanent_AddressLine2=data.permanent_AddressLine2;
    this.permanent_Landmark=data.permanent_Landmark;
    this.permanent_City=data.permanent_City;
    this.permanent_Pincode=data.permanent_Pincode;
    this.permanent_Statecode=data.permanent_Statecode;
    this.gstIN=data.gstIN;
  }
} 