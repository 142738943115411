import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { checkLoginresult, checkLoginparam, StockCatcheckLoginoutput } from './login.component';
import { BehaviorSubject, Observable,lastValueFrom } from 'rxjs';
import { ApplicationService } from 'src/app/common-service/application.service';
import { environment } from 'src/environments/environment';
import { first  } from 'rxjs/operators';
import { AccountService } from 'src/app/common-service/account.service';

@Injectable({
  providedIn: 'root'
})

export class LoginService {


  constructor(public http: HttpClient,
    public Application: ApplicationService,
    private accountService: AccountService
  ) { }

  async Getall(data: checkLoginparam): Promise<checkLoginresult[]> {
    let serializedForm = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
    return this.http.post<checkLoginresult[]>(environment.apiUrl + 'api/CheckLogin', data).toPromise();
  }

  async GetStockCatLogin(username, password, MPIN, remember): Promise<checkLoginresult> {

    const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
    let params = new HttpParams().set("UserName", username).set("Password", password).set("MPIN", MPIN);
    this.accountService.StockCatlogin(username, password, MPIN, remember)
      .pipe(first())
      .subscribe();
    return this.http.get<checkLoginresult>(environment.benzosoftapiUrl + 'api/CheckBenzoLogin', { params: params }).toPromise();
  }


  
}

