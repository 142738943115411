<div class="w-full h-full bg-pattern flex flex-col items-center justify-center">
  <div @fadeInUp class="card overflow-hidden w-full max-w-md">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="w-16" src="assets/img/demo/logo.svg">
      </div>
    </div>

    <div class="text-center mt-4">
      <h2 class="title m-0">Welcome back</h2>
      <h4 class="body-2 text-secondary m-0">Sign in with your credentials below.</h4>
    </div>
    <form (ngSubmit)="send(form)" [formGroup]="form">

    <div class="p-6 flex flex-col gap-4">
      <div class="flex flex-col">
        <mat-form-field class="flex-1" appearance="outline">
          <mat-label>USER-ID</mat-label>
          <input formControlName="username" matInput required>
          <mat-error *ngIf="form.get('username').hasError('required')">We need an email address to log you in</mat-error>
        </mat-form-field>
        <mat-form-field class="flex-1" appearance="outline">
          <mat-label>PASSWORD</mat-label>
          <input [type]="inputType" formControlName="password" matInput required>
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
            <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
            <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
          </button>
          <mat-hint>Click the eye to toggle visibility</mat-hint>
          <mat-error *ngIf="form.get('password').hasError('required')">We need a password to log you in</mat-error>
        </mat-form-field>
      </div>

      <div class="flex items-center justify-between">
        <mat-checkbox class="caption" color="primary">Remember Me</mat-checkbox>
        <a [routerLink]="['/forgot-password']" class="caption">Forgot Password?</a>
      </div>

      <button  color="primary"  mat-raised-button type="submit" (click)="send(form)">
        SIGN IN
      </button>

      <p class="text-secondary text-center">
        Don't have an account?<br/>
        <a [routerLink]="['/register']">Click here to create one</a>
      </p>
    </div>
    </form>
  </div>
</div>
