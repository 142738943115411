import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
 
 
@Injectable({
  providedIn: 'root'
})
export class QuickCustomerEntryService {

  constructor(public http: HttpClient) { }
 

  public GetCustomerSearch(LoginOUID, LoginUserId, LoginRoleId, currentOUID): Observable<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", LoginOUID).set("LoginUserID", LoginUserId).set("LoginRoleID", LoginRoleId).set("OUID", currentOUID);
    return this.http.get<object>(environment.apiUrl + "api/CustomerSearchHelper", { params: params });
  }

  async GetCustomerDetail(OUID, UserId, RoleId, CustomerID): Promise<object> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams().set("LoginOUID", OUID).set("LoginUserID", UserId).set("LoginRoleID", RoleId).set("customerID",CustomerID);
    return this.http.get<object>(environment.apiUrl + "api/Customer", { params: params }).toPromise();
  }

 
  
}
