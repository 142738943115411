import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';


import { ApplicationService } from 'src/app/common-service/application.service';
import { AccountService } from 'src/app/common-service/account.service';
 

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(private readonly popoverRef: PopoverRef,
    public Application: ApplicationService,
    private accountService: AccountService) { }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    this.accountService.logout();
    setTimeout(() => this.popoverRef.close(), 250);
  }
}
