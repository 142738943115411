 <form (ngSubmit)="save()" [formGroup]="entryform">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 class="headline m-0" fxFlex="auto">New Customer</h2>
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>
    <mat-divider class="text-border"></mat-divider>
    <mat-dialog-content fxLayout="column">
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto" appearance="outline" required>
                <mat-label class="text-primary">First Name</mat-label>
                <input formControlName="firstName" class="text-primary" cdkFocusInitial matInput
                required autocomplete="off">
                <mat-icon matPrefix svgIcon="mat:person"></mat-icon>
            </mat-form-field>
            <mat-form-field class="sm:ml-6  flex-auto" appearance="outline">
                <mat-label class="text-primary">Last Name</mat-label>
                <input formControlName="lastName" testPipe matInput autocomplete="off">
                <mat-icon matPrefix svgIcon="mat:person"></mat-icon>
            </mat-form-field>
        </div>

        <mat-form-field class="flex-auto" appearance="outline">
            <mat-label class="text-primary">Phone Number</mat-label>
            <input (keypress)="numberOnly($event)" maxlength="15" minlength="10"
            formControlName="personalContactNo" matInput required autocomplete="off">
            <mat-icon matPrefix svgIcon="mat:phone"></mat-icon>
        </mat-form-field>
        <mat-form-field class="flex-auto" appearance="outline">
            <mat-label class="text-primary">Email</mat-label>
            <input #memberemail formControlName="personalEmail" matInput autocomplete="off"
            (blur)="validateEMAIL(memberemail.value)">
            <mat-icon matPrefix svgIcon="mat:mail"></mat-icon>
            <p style="color:red;" *ngIf="error">
            Invalid
            Email</p>
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="outline">
            <mat-label class="text-primary">Flat,HouseNo</mat-label>
            <input formControlName="residential_AddressLine1" matInput autocomplete="off">
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="outline">
            <mat-label class="text-primary">Street,Area,Sector,Village</mat-label>
            <input formControlName="residential_AddressLine2" matInput autocomplete="off">
            <mat-icon matPrefix svgIcon="mat:edit_location"></mat-icon>
        </mat-form-field>    

        <mat-form-field class="flex-auto" appearance="outline">
            <mat-label class="text-primary">Landmark</mat-label>
            <input formControlName="residential_Landmark" matInput autocomplete="off">
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="outline">
            <mat-label class="text-primary">Pincode</mat-label>
            <input (keypress)="numberOnly($event)" maxlength="6"
            formControlName="residential_Pincode" required matInput autocomplete="off">
            <mat-icon matPrefix svgIcon="mat:my_location"></mat-icon>
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="outline">
            <mat-label class="text-primary">Town,City</mat-label>
            <input formControlName="residential_City" matInput autocomplete="off">
            <mat-icon matPrefix svgIcon="mat:location_city"></mat-icon>
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="outline">
            <mat-label class="text-primary">Select State / Province / Region</mat-label>
            <mat-select (selectionChange)="OnChangeleadState()"
                formControlName="residential_Statecode" matselect required autocomplete="off">
                <mat-option *ngFor="let _state of combo_state" [value]="_state.code">
                {{ _state.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">CANCEL</button>
        <button *ngIf="isCreateMode()" [disabled]="entryform.invalid" color="primary" mat-button
            type="submit">Add Customer</button>
        <button *ngIf="isUpdateMode()" [disabled]="entryform.invalid" color="primary" mat-button
            type="submit">Update Customer</button>
    </mat-dialog-actions>
</form>