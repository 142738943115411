import { Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApplicationService } from 'src/app/common-service/application.service';
@Component({
  selector: 'vex-dialog-result',
  templateUrl: './dialog-result.component.html',
  styleUrls: ['./dialog-result.component.scss']
})
export class DialogResultComponent implements OnInit {
  content: string;
  content1: string;
  content2: string;

  heading: string;
  error: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<DialogResultComponent>,
    public Application: ApplicationService ,    
  ) { }
  ngOnInit(): void {
    this.content = this.defaults.content;
    this.content1 = this.defaults.content1;
    this.content2 = this.defaults.content2;
    this.heading = this.defaults.heading;
    this.error = this.defaults.error;
  }

}
