import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'; 

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Compactcustomer } from './../../../common-interface/customercompact-interface.module';
import { ApplicationService, Combodet } from './../../../common-service/application.service'
import { LoaderService } from './../../../common-service/loader.service'

import { fadeInUp400ms } from './../../../../@vex/animations/fade-in-up.animation';
import { fadeInRight400ms } from './../../../../@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from './../../../../@vex/animations/scale-in.animation';
import { stagger40ms } from './../../../../@vex/animations/stagger.animation';
import { stagger80ms } from './../../../../@vex/animations/stagger.animation';
 
@Component({
  selector: 'vex-quick-customer-entry',
  templateUrl: './quick-customer-entry.component.html',
  styleUrls: ['./quick-customer-entry.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger80ms,
    stagger40ms
  ]
})
export class QuickCustomerEntryComponent implements OnInit {

 entryform: UntypedFormGroup;

  mode: 'create' | 'update' = 'create';

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  error: boolean = false;
  combotype: any;
  combo_state: Combodet[]=[];
  compactcustomer: Compactcustomer;

  constructor(public dialogRef: MatDialogRef<QuickCustomerEntryComponent>,
      @Inject(MAT_DIALOG_DATA) public defaults: Compactcustomer,    
      public fb: UntypedFormBuilder,
      public Application: ApplicationService,
      public cd: ChangeDetectorRef,
      public LoaderService: LoaderService,
  ) { this.error = false; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngOnInit(): void {
    if (this.defaults) {
      this.mode = 'update';
      this.compactcustomer = this.defaults
    } else {
      this.defaults = {} as Compactcustomer;
    }
    this.entryform = this.fb.group({
      firstName: this.defaults.firstName || '',
      lastName: this.defaults.lastName || '',
      personalContactNo: this.defaults.personalContactNo || '',
      personalEmail: this.defaults.personalEmail || '',
      residential_AddressLine1: this.defaults.residential_AddressLine1 || '',
      residential_AddressLine2: this.defaults.residential_AddressLine2 || '',
      residential_Landmark: this.defaults.residential_Landmark || '',
      residential_City: this.defaults.residential_City || '',
      residential_Pincode: this.defaults.residential_Pincode || '',
      residential_Statecode: this.defaults.residential_Statecode || this.Application.statecode ,
      stateName: this.defaults.stateName || ''
    });

    this.loadState();
  }

  OnChangeleadState() {

    let state = this.combo_state.filter(Status => Status.code === this.entryform.get('residential_Statecode').value);
    this.entryform.patchValue({ stateName: state[0].description });
  }

  loadState() {
    this.combotype = 'STATE';
    this.Application.Loadcombo_NonNumeric(this.Application.ouid, this.Application.userID, this.Application.roleID, this.combotype).then(resp => {
      this.combo_state = resp['result'].data;
      this.OnChangeleadState();
    });
  }
  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateeCustomer();
    }
  }
  createCustomer() {

    const comcustomer = this.entryform.value;
    event.preventDefault();
    this.dialogRef.close(comcustomer);
  }

  updateeCustomer() {
    const comcustomer = this.entryform.value;
    this.dialogRef.close(comcustomer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  validateEMAIL(event: string) {
    this.error = false;
    var regexp = new RegExp(this.emailPattern);
    var regex = regexp;
    if (regex.test(event)) {
      this.error = false;
      return true;
    } else if (event !== "") {
      this.error = true;
      this.entryform.get('personalEmail').setErrors({ 'Invalid Email...!': 'error' });
      return false;
    }
  }

}
